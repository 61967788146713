import React from "react";
import "./buttonPanel.scss";
import l1 from "./img/l1.png";
import l2 from "./img/l2.png";
import l3 from "./img/l3.png";

export function ButtonPanel() {
    return (
        <div className={"ButtonPanel"}>
            <div className={"ButtonItem"}>
                <img className={"Img"} src={l1}>

                </img>
                <div className={"Title"}>
                    明厨亮灶
                </div>
            </div>
            <div className={"ButtonItem"}>
                <img className={"Img"} src={l2}>

                </img>
                <div className={"Title"}>
                    可视化平台
                </div>
            </div>
            <div className={"ButtonItem"}>
                <img className={"Img"} src={l3}></img>
                <div className={"Title"}>
                    态势感知
                </div>
            </div>
        </div>
    )
}