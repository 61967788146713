import "./dataPanelB.scss";
import React, {useEffect, useRef} from "react";
import {DataPanel} from "../../common/dataPanel";
import * as echarts from "echarts";


export function DataPanelB() {
    const chatB = useRef()

    useEffect(() => {
        let echartsView = echarts.init(chatB.current);
        echartsView.setOption({
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {},
            grid: {
                left: '3%',
                right: '4%',
                bottom: '3%',
                top: "10",
                containLabel: true
            },
            xAxis: [
                {
                    showBackground: true,
                    type: 'value',
                    splitLine: {
                        show: false
                    },
                    show: false,
                    max: 90

                },
            ],
            yAxis: [
                {
                    inverse: true,
                    axisLine: { show: false },
                    axisLabel: {
                        show: true,
                        inside: true,
                        verticalAlign: "bottom",
                        margin: 0,
                        padding: [0,0,8,0],
                        color: "rgb(145,210,255)",
                    },
                    axisTick: { show: false },
                    splitLine: { show: false },
                    type: 'category',
                    data: ['织金县', '金沙县', '赫章县', '黔西市', '威宁彝族回族苗族自治县', '百里杜鹃管理区',"七星关区","大方县","纳雍县"]
                }
            ],
            series: [
                {
                    showBackground: true,
                    type: 'bar',
                    stack: "component",
                    z: 3,
                    barWidth: 5,
                    realtimeSort: true,
                    roundCap: true,
                    itemStyle: {
                        color: {
                            type: "liner",
                            x: 0,
                            Y: 0.5,
                            x2: 1,
                            y2: 0.5,
                            colorStops: [
                                {offset: 0,color:'rgb(253,106,105)'},
                                {offset: 1,color:'rgb(255,168,63)'},
                            ]
                        },
                        borderRadius: 8,
                    },
                    data: [80, 74, 68, 65, 63, 61,59,55,51]
                },
            ]
        })
    }, []);

    return (
        <DataPanel>
            <div className={"PanelTitle"}>可视化平台</div>
            <div className={"DataViewPanel"}>
                <div className={"BData"} ref={chatB}>
                </div>
            </div>
        </DataPanel>
    )
}