import React from "react";
import "./dataPaneD.scss";
import {DataPanel} from "../../common/dataPanel";

export function DataPanelDLine({keyStr,valueStr,isPre,index}) {

    if (index === undefined) {
        index = 0
    }
    let background = undefined;
    if (isPre) {
        background = "rgb(2,84,191)";
    } else {
        if (index % 2 === 0) {
            background = "rgba(128,128,128,0.4)"
        } else {
            background = undefined
        }
    }

    return (
        <div className={"DataLine"} style={{background: background}}>
            <div className={"Key"}>{keyStr}</div>
            <div className={"Value"}>{valueStr}</div>
        </div>
    )
}

export function DataPanelD() {
    return (
        <DataPanel>
            <div className={"DataViewPanel"}>
                <div className={"PanelTitle"}>工作交办</div>
                <div className={"ButtonLabelLine"}>
                    <div className={"ButtonLineActive"}>学生统计</div>
                    <div className={"ButtonLine"}>采购清单</div>
                    <div className={"ButtonLine"}>老师培训</div>
                </div>
                <div className={"Data"}>
                    <DataPanelDLine keyStr={"类别"} valueStr={"数量"} isPre={true} index={0}/>
                    <div className={"DataContent"}>
                        <DataPanelDLine keyStr={"在校生总数"} valueStr={"1836473"}  index={1}/>
                        <DataPanelDLine keyStr={"小学在校生"} valueStr={"819533"} index={2}/>
                        <DataPanelDLine keyStr={"初中在校生"} valueStr={"424007"} index={3}/>
                        <DataPanelDLine keyStr={"在幼儿园"} valueStr={"301544"} index={4}/>
                        <DataPanelDLine keyStr={"普通高中在校生"} valueStr={"195325"} index={5}/>
                        <DataPanelDLine keyStr={"高职高专"} valueStr={"46321"} index={6}/>
                        <DataPanelDLine keyStr={"中等职业学校在校生"} valueStr={"34949"} index={7}/>
                        <DataPanelDLine keyStr={"市属高等院校本科生"} valueStr={"12798"} index={8}/>
                        <DataPanelDLine keyStr={"特殊教育在校生"} valueStr={"9878"} index={9}/>
                        <DataPanelDLine keyStr={"专门学校在校生"} valueStr={"317"} index={10}/>
                    </div>
                </div>
            </div>
        </DataPanel>
    )
}
