import "./dataPanel.scss"
import React from "react";
import k1 from "./img/k1.png"
import ReactDOM from "react-dom/client";

export function DataPanel({children}) {
    console.log(children)
    return (
        <div
            className={"DataPanelContent"}
        >
            <img src={k1} className={"BKImg"}/>
            {children}
        </div>
    )
}
