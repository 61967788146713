export const MarksData = {
    "七星关区": {
        GPS: [105.292444,27.284739],
        Label: "8000",
        Children: [
            {
                Label: "毕节金海湖新区梨树镇中心幼儿园",
                GPS: [105.397961,27.257429]
            },
            {
                Label: "七星关区中等职业学校",
                GPS: [105.300431,27.31669]
            },
            {
                Label: "毕节幼儿师范高等专科学校",
                GPS: [105.431015,27.211557]
            }
        ]
    }
}