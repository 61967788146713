import "./map.scss"
import React, {useEffect, useState} from "react";
import AMapLoader from "@amap/amap-jsapi-loader"
import {clear} from "@testing-library/user-event/dist/clear";
import {MarksData} from "./marks";
import markImg from "./img/poi-marker-default.png"

let nowArea = "毕节市";
const setNowArea = (area) => {
    nowArea = area
}
let click = () => {}

export function Map() {
    const [lastArea, setLastArea] = useState("毕节市");
    const [showBack, setShowBack] = useState(false)
//绘制遮罩层
    useEffect(() => {
        window._AMapSecurityConfig = {
            securityJsCode: "0bd06fa1ef2f950e344e93be4bb5dcb0",
        }
        AMapLoader.load({
            key: "448ed2bcfd130e8c16845a3d1f0cbb5a",
            plugins: ["AMap.DistrictSearch","AMap.Scale","AMap.ToolBar","AMap.DistrictLayer"]
        }).then(AMap => {
            let index = 0;
            //初始化地图
            let map = new AMap.Map("map",{
                layers: [],
                mapStyle: 'amap://styles/53df08d38a0da2ba2a2a3ad0b49d631f',
                features: ['bg'],
            });
            click = () => {
                console.log("click")
                index = 0;

                setTimeout(() => {
                    setNowArea("毕节市")
                    clearPolygon()
                    drawPolygon(nowArea)
                },200)
                map.setZoom(8,false,200)
            }

            const drawMarks = () => {
                if (index === 0) {
                    for (const key in MarksData) {
                        const data = MarksData[key];
                        const gps = data.GPS;
                        const marker = new AMap.Marker({
                            position: gps,
                            icon: markImg,
                            anchor:'center',
                            offset: new AMap.Pixel(0, 0)
                        });
                        marker.setMap(map);
                        marker.setLabel({
                            direction:'bottom',
                            offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
                            content: data.Label, //设置文本标注内容
                        });
                    }
                } else {
                    const obj = MarksData[nowArea];
                    if (obj !== undefined) {
                        for (const item of obj.Children) {
                            const gps = item.GPS;
                            const marker = new AMap.Marker({
                                position: gps,
                                icon: markImg,
                                anchor:'center',
                                offset: new AMap.Pixel(0, 0)
                            });
                            marker.setMap(map);
                            marker.setLabel({
                                direction:'bottom',
                                offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
                                content: item.Label, //设置文本标注内容
                            });
                        }
                    }
                }
            };

            //依据区域绘制遮罩
            const drawPolygon = (area: string) => {
                setNowArea(area)
                clearPolygon()
                console.log(area);
                new AMap.DistrictSearch({
                    extensions: 'all',
                    subdistrict:0
                }).search(area,(status,result) => {
                    if (index === 0) {
                        let holes = result.districtList[0].boundaries
                        let move = new AMap.Polygon( {
                            path: holes,
                        });
                        // polygon.setPath(pathArray);
                        map.setCenter(result.districtList[0].center)
                        map.setFitView(
                            [move],
                            false,  // 动画过渡到制定位置
                            [60, 60, 70, 60],  // 周围边距，上、下、左、右
                            20,  // 最大 zoom 级别
                        );//地图自适应

                        drewSubPolygon(area);
                        setShowBack(false)
                    } else {
                        let holes = result.districtList[0].boundaries;
                        let polygon = new AMap.Polygon({
                            path: holes,
                            fillColor: 'rgba(30,85,150)',
                            fillOpacity: 0.4,
                            strokeOpacity: 1,
                            strokeColor: 'rgba(129,214,236)',
                            strokeWeight: 1,
                            strokeStyle: 'dashed',
                            strokeDasharray: [5, 5],
                            zIndex: 50,
                        });
                        map.add(polygon);
                        setShowBack(true)
                    }
                    drawMarks();

                })
            }
            const drewSubPolygon = (area: string) => {
                // 获取下级行政区划
                if (index === 0) {
                    let opts = {
                        subdistrict: 1,   //获取边界不需要返回下级行政区
                        extensions: 'all',  //返回行政区边界坐标组等具体信息
                        level: 'biz_area'  //查询行政级别为 市
                    };

                    let district = new AMap.DistrictSearch(opts);//注意：需要使用插件同步下发功能才能这样直接使用
                    district.search(area,(status,result) => {
                        if (result.districtList !== undefined) {
                            let list = result.districtList[0].districtList;
                            if (list === null || list === undefined) {
                                return
                            }

                            //行政区划查询
                            let layer = new AMap.LabelsLayer({
                                // 开启标注避让，默认为开启，v1.4.15 新增属性
                                collision: false,
                                // 开启标注淡入动画，默认为开启，v1.4.15 新增属性
                                animation: true,
                            });

                            for (const item of list) {
                                let name = item.name;
                                let adcode = item.adcode;
                                let labelsMarker = new AMap.LabelMarker({
                                    position: [item.center.lng, item.center.lat],
                                    opacity: 1,
                                    text: {
                                        content: name,
                                        direction: 'center',
                                        style: {
                                            fontSize: 12,
                                            fillColor: '#fff',
                                            padding: [3, 10],
                                            borderColor: '#ccc',
                                            borderWidth: 3,
                                        }
                                    }
                                });
                                layer.add(labelsMarker);
                                if (index === 0) {
                                    new AMap.DistrictSearch({
                                        subdistrict: 1,   //获取边界不需要返回下级行政区
                                        extensions: 'all',  //返回行政区边界坐标组等具体信息
                                        level: 'biz_area'  //查询行政级别为 市
                                    }).search(adcode,(status,result) => {
                                        if (result.districtList !== undefined ) {
                                            let bounds = result.districtList[0].boundaries;
                                            //生成行政区划polygon
                                            for (let i = 0; i < bounds.length; i += 1) {//构造MultiPolygon的path
                                                bounds[i] = [bounds[i]]
                                            }
                                            let polygon = new AMap.Polygon({
                                                path: bounds,
                                                fillColor: 'rgba(30,85,150)',
                                                fillOpacity: 0.4,
                                                strokeOpacity: 1,
                                                strokeColor: 'rgba(129,214,236)',
                                                strokeWeight: 1,
                                                strokeStyle: 'dashed',
                                                strokeDasharray: [5, 5],
                                                zIndex: 50,
                                            });
                                            if (index === 0) {
                                                polygon.on('mouseover',() => {
                                                    polygon.setOptions({
                                                        fillColor: 'white',
                                                        strokeOpacity: 0.8,
                                                    })
                                                })
                                                polygon.on('mouseout', () => {
                                                    polygon.setOptions({
                                                        fillColor: 'rgba(30,85,150)',
                                                    })
                                                })
                                                polygon.on('click',(event) => {
                                                    console.log(event);
                                                    map.remove(layer)
                                                    if (index >= 1) {
                                                        return
                                                    } else {
                                                        index++;
                                                        setTimeout(() => {
                                                            clearPolygon()
                                                            drawPolygon(name)
                                                        },200)
                                                        map.setZoomAndCenter(12,[item.center.lng, item.center.lat],false,200)
                                                    }
                                                })
                                            }
                                            map.add(polygon)
                                        }
                                    })
                                } else {

                                }
                            }
                            map.add(layer);
                        } else {
                            return;
                        }

                    })
                }

            }

            const clearPolygon = () => {
                map.clearMap();
            }

            drawPolygon(nowArea)
        })
    }, []);
    return (
        <div style={{width: "100%",height: "100%"}}>
            {showBack ? <div
                className={"Back"}
                onClick={() => {
                    click()
                }}
            >返回</div> : undefined}
            <div id={"map"} className={"CenterMap"}/>
        </div>
    )
}