import React, {useEffect, useRef, useState} from "react";
import "./home.scss"
import {Map} from "../common/map";
import {DataPanel} from "../common/dataPanel";
import {DataPanelA} from "./dataPanelA";
import bk1 from "./img/bk1.png"
import {DataPanelB} from "./dataPanelB/dataPanelB";
import {DataPanelC} from "./dataPanelC/dataPanelC";
import {DataPanelD} from "./dataPanelD";
import {ButtonPanel} from "./buttonPanel";
import headbg from "./img/headbg.png"

export function Home() {
    const [timeNow, setTimeNow] = useState("2024年09月01日 00:00:00")
    useEffect(() => {
        setInterval(() => {
            const timer = new Date();
            const mon = timer.getMonth() < 10? `0${timer.getMonth()}` : `${timer.getMonth()}`
            const day = timer.getDay() < 10? `0${timer.getDay()}` : `${timer.getDay()}`
            const hour = timer.getHours() < 10? `0${timer.getHours()}` : `${timer.getHours()}`
            const min = timer.getMinutes() < 10? `0${timer.getMinutes()}` : `${timer.getMinutes()}`
            const sec = timer.getSeconds() < 10? `0${timer.getSeconds()}` : `${timer.getSeconds()}`
            setTimeNow(`${timer.getFullYear()}年${mon}月${day}日 ${hour}:${min}:${sec}`)
        },750)
    }, []);
    return (
        <div className={"Home"} style={{background: `url(${bk1})`,backgroundSize: "100%,100%"}}>
            <ButtonPanel/>

            <div className={"Header"}>
                <div className={"HL"}></div>
                <div className={"HCB"}>
                    <img className={"BImg"} src={headbg}/>
                    <div className={"Text"}>毕节市教育监管平台</div>
                </div>
                <div className={"HR"}>
                    <div className={"Time"}>{timeNow}</div>
                </div>
            </div>
            <div className={"Content"}>
                <div className={"Left"}>
                    <div className={"A1"}>
                        <DataPanelA/>
                    </div>
                    <div className={"A2"}>
                        <DataPanelB/>
                    </div>
                </div>
                <div className={"Map"}>
                    <Map />
                </div>
                <div className={"Right"}>
                    <div className={"A3"}>
                        <DataPanelC/>
                    </div>
                    <div className={"A4"}>
                        <DataPanelD/>
                    </div>
                </div>
            </div>
        </div>
    )
}