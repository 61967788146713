import "./app.scss"
import {Home} from "./home";

function App() {
  return (
    <div className="App">
      <Home></Home>
    </div>
  );
}

export default App;
