import React, {useEffect, useRef} from "react";
import {DataPanel} from "../../common/dataPanel";
import "./dataPanelA.scss";
import * as echarts from "echarts";

export function DataPanelA() {
    const chartA = useRef()
    useEffect(() => {
        let dataAChart = echarts.init(chartA.current)
        dataAChart.setOption({
            polar: {
                radius: [80, '50%']
            },
            angleAxis: {
                max: 100,
                startAngle: 90,
                axisLine: {
                    show: true,
                    lineStyle: {
                        color: 'rgb(72,94,211)'
                    }
                },
                axisTick: {
                    show: false,
                },
                minorTick: {
                    show: false
                },
                axisLabel: {
                    show: false,
                },
                splitLine: {
                    show: false
                }
            },
            radiusAxis: {
                type: 'category',
                data: ['']
            },
            tooltip: {},
            series: {
                type: 'bar',
                data: [64],
                coordinateSystem: 'polar',
                label: {
                    show: false,
                    position: 'middle',
                    formatter: '{b}: {c}'
                },
                itemStyle: {
                    color: {
                        type: "liner",
                        x: 0,
                        Y: 0.5,
                        x2: 1,
                        y2: 0.5,
                        colorStops: [
                            {offset: 0,color:'rgb(95,78,232)'},
                            {offset: 1,color:'rgb(41,167,186)'},
                        ]
                    },
                }
            }
        })
    }, []);

    return (
        <DataPanel>
            <div className={"PanelTitle"}>明厨亮灶</div>
            <div className={"ADataPanel"}>
                <div className={"NumberPanel"}>
                    <div className={"NumberTitle"}>接入总数</div>
                    <div className={"Number"} style={{color: "rgb(2,120,255)"}}>3433</div>
                </div>
                <div className={"NumberPanel"}>
                    <div className={"NumberTitle"}>在线总数</div>
                    <div className={"Number"} style={{color: "rgb(0,247,186)"}}>2200</div>
                </div>
            </div>
            <div className={"ADataViewPanel"}>
                <div className={"AData"}>
                    <div className={"AView"} ref={chartA}></div>
                    <div className={"ADNumber"}>64%</div>
                    <div className={"ADTitle"}>接入率</div>
                </div>
            </div>
        </DataPanel>
    )
}