import "./dataPanelC.scss";
import React, {useEffect, useRef} from "react";
import {DataPanel} from "../../common/dataPanel";
import * as echarts from "echarts";


export function DataPanelC() {
    const chatC = useRef()

    useEffect(() => {
        let echartsView = echarts.init(chatC.current);
        echartsView.setOption({
            title: {
              show: false,
            },
            grid: {
                left: 40,
                right: 15,
                bottom: 80,
                top: 20,
            },
            xAxis: [
                {
                    type: 'category',
                    boundaryGap: false,
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(13,44,88,0.7)"
                        }
                    }
                },
            ],
            yAxis: [
                {
                    type: 'value',
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(13,44,88,0.7)"
                        }
                    }
                }
            ],
            series: [
                {
                    type: 'line',
                    symbol: 'none',
                    itemStyle: {
                    },
                    data: [
                        ["",90],
                        ["2024-07-09",90],
                        ["2024-07-10",70],
                        ["2024-07-11",60],
                        ["2024-07-12",90],
                        ["2024-07-13",90],
                    ]
                },
            ]
        })
    }, []);

    return (
        <DataPanel>
            <div className={"PanelTitle"}>态势感知</div>
            <div className={"DataViewPanel"}>
                <div className={"ADataPanel"}>
                    <div className={"NumberPanel"}>
                        <div className={"NumberTitle"}>日志数量</div>
                        <div className={"Number"} style={{color: "rgb(0,247,186)"}}>22322</div>
                    </div>
                    <div className={"NumberPanel"}>
                        <div className={"NumberTitle"}>总告警数</div>
                        <div className={"Number"} style={{color: "rgb(255,157,31)"}}>343</div>
                    </div>
                </div>
                <div className={"ADataPanel"}>
                    <div className={"NumberPanel"}>
                        <div className={"NumberTitle"}>漏洞数</div>
                        <div className={"Number"} style={{color: "rgb(252,87,87)"}}>12</div>
                    </div>
                    <div className={"NumberPanel"}>
                        <div className={"NumberTitle"}>在线总数</div>
                        <div className={"Number"} style={{color: "rgb(0,247,186)"}}>3433</div>
                    </div>
                </div>
            </div>
            <div className={"CData"} ref={chatC}>

            </div>
        </DataPanel>
    )
}